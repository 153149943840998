function getCookie(name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length == 2)
    return parts.pop().split(';').shift();
}

function setCookie(name, value, days) {
  var date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  var expires = '; expires=' + date.toGMTString();
  document.cookie = name + '=' + value + expires + ';path=/';
}

function getParam(p) {
  var match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this, args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

function superscript() {
  jQuery(function($) {
    $('body :not(script,sup)').contents().filter(function() {
      return this.nodeType === 3
    }).replaceWith(function() {
      return this.nodeValue.replace(/[™®]/g, '<sup>$&</sup>')
    })
  })
}

jQuery.fn.extend({
  equalHeights: function() {
    jQuery(this).each(function(index, item) {
      var columns = jQuery(item).find('.equal-heights')
      if(window.innerWidth >= 768) {
        jQuery(item).removeClass('is-resized')
        columns.css('height', 'auto')
        var h = columns.map(function() {
          return jQuery(this).outerHeight()
        }).get()
        var mh = Math.max.apply(null, h)
        columns.css('height', mh)
        jQuery(item).addClass('is-resized')
      } else {
        jQuery(item).removeClass('is-resized')
        columns.css('height', 'auto')
      }
    })
  }
})
